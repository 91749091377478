import axios from 'axios'
const baseUrl = import.meta.env.VITE_STATIC_HOSTNAME

export async function fetchAllHiddenBJs() {
  return axios.get(`${baseUrl}/api/hidden/bj/get`).then(resp => resp.data)
}

export async function fetchAllHiddenDonors() {
  return axios.get(`${baseUrl}/api/hidden/donor/get`).then(resp => resp.data)
}
